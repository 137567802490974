import React, { useRef, useState } from 'react'

import _ from 'lodash'
import Link from 'next/link'
import ContentLoader from 'react-content-loader'
import TextTruncate from 'react-text-truncate'
import { Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useRecords } from '@tootz/react-admin'

import { kinds } from './Config/Links/config'

const HomeCards = () => {
  const { records: cards } = useRecords({
    r: '/portal/home/cards',
    queryParams: {
      sort: {
        field: 'position',
        order: 'asc'
      }
    }
  })
  const cardsSlider = useRef()
  const [selectedKind, setSelectedKind] = useState(null)
  const getCardKind = kind => kinds.find(filter => filter.slug == kind)
  const filteredCards = selectedKind
    ? cards.filter(card => card.kind == selectedKind)
    : cards

  if (!cards || !cards.length) return <></>
  return (
    <div>
      <div className="d-flex flex-wrap flex-md-nowrap justify-content-between">
        <div className="d-flex mb-4">
          <button
            type="button"
            className={`px-2 py-1 me-2 rounded-1 border text-gray-700 fw-bold d-flex align-items-center bg-transparent ${
              !selectedKind ? 'border-gray-500' : 'border-gray-400 border-hover'
            }`}
            onClick={() => setSelectedKind(null)}
          >
            Todos
          </button>
          {kinds.map(filter => (
            <button
              key={`filter-${filter.slug}`}
              type="button"
              className={`px-2 py-1 me-2 rounded-1 border text-gray-700 fw-bold d-flex align-items-center bg-transparent ${
                selectedKind == filter.slug
                  ? 'border-gray-500'
                  : 'border-gray-400 border-hover'
              } ${filter.soon ? 'opacity-75 border-0' : ''}`}
              onClick={() =>
                filter.soon ? null : setSelectedKind(filter.slug)
              }
            >
              {filter.variant && (
                <span
                  className={`bg-${filter.variant} rounded-circle h-8px w-8px me-2`}
                />
              )}
              {filter.name}
            </button>
          ))}
        </div>
        <div className="d-flex align-items-center justify-content-end mb-4 ms-auto">
          <button
            className="d-flex border-0 bg-transparent p-1"
            onClick={() => cardsSlider.current.swiper.slidePrev()}
          >
            <i className="fal fa-square-chevron-left fs-3 text-gray-500 text-hover-gray-600"></i>
          </button>
          <button
            className="d-flex border-0 bg-transparent p-1 ms-1"
            onClick={() => cardsSlider.current.swiper.slideNext()}
          >
            <i className="fal fa-square-chevron-right fs-3 text-gray-500 text-hover-gray-600"></i>
          </button>
        </div>
      </div>
      <Swiper
        ref={cardsSlider}
        slidesPerView={1}
        spaceBetween={10}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 5000 }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 30
          }
        }}
      >
        {filteredCards &&
          filteredCards.map((card, i) => {
            const kind = getCardKind(card.kind)

            return (
              <SwiperSlide key={`card-${i}`} className="overflow-initial">
                <Link
                  href={card?.link_url || ''}
                  passHref
                  className={`rounded-3 shadow-primary p-6 d-flex flex-column bg-${kind.variant} mt-5 mb-10 text-white h-175px bgi-position-center bgi-size-cover position-relative bg-hover-opacity-75 transform-hover-scale-lg home-card`}
                  target="_blank"
                  style={{
                    height: '180px'
                  }}
                >
                  <div
                    className={
                      'position-absolute top-0 start-0 w-100 h-100 rounded-3 mix-blend-mode-soft-light bgi-size-cover bgi-position-center'
                    }
                    style={{
                      backgroundImage: `url(${_.get(card, 'image.file.url')})`
                    }}
                  ></div>
                  <div className="position-relative h-100 d-flex flex-column">
                    <span className="fs-4 fw-bolder text-white">
                      {card.name}
                    </span>
                    <TextTruncate
                      line={4}
                      element="p"
                      truncateText="…"
                      text={card.description}
                      className="home-card-subtitle"
                    />
                    <div className="d-flex justify-content-between align-items-center mt-auto">
                      <div></div>

                      <span className="text-reset">
                        <i
                          className={`fal fa-${kind.icon} fs-2x text-white`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          })}

        {(!cards || cards.length == 0) &&
          [1, 2, 3, 4, 5].map(cardIndex => (
            <SwiperSlide
              key={`card-${cardIndex}`}
              className="overflow-hidden rounded-3"
            >
              <ContentLoader width={300} height={175} viewBox="0 0 300 175">
                <rect x="0" y="0" rx="8" ry="8" width="300" height="175" />
              </ContentLoader>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}

export default HomeCards
