import { useEffect, useState } from 'react'

import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useSessionContext } from '@tootz/react-admin'

import UserProfileValidationModal from './ProfileValidationModal'

export const UsersProfileValidationWidget = () => {
  const router = useRouter()
  const [showModal, setShowModal] = useState(null)
  const { userValidations, user } = useSessionContext()
  const userFirstName = _.get(user, 'name', '').split(' ')[0]
  const closeModal = () => setShowModal(false)
  const handleCompleteProfile = () => setShowModal('profile')

  useEffect(() => {
    if (
      router.asPath !== '/minha-conta/configuracoes' &&
      userValidations &&
      userValidations.incompleteAccount
    )
      setShowModal('profile')
  }, [userValidations])

  if (userValidations && userValidations.incompleteAccount)
    return (
      <>
        <div
          className="bg-dark w-100 rounded-2 p-10 pt-9 mb-10 text-white fw-bolder d-flex align-items-center flex-wrap flex-md-nowrap cursor-pointer"
          onClick={handleCompleteProfile}
        >
          <div className="pe-md-10 mb-4 mb-md-0">
            <div className="d-block mb-2 fs-1">
              <span className="fw-bolder">{userFirstName}</span>
              <span
                className="fw-bold"
                // style={{
                //   background: 'linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)',
                //   '-webkit-background-clip': 'text',
                //   '-webkit-text-fill-color': 'transparent'
                // }}
              >
                , complete seu perfil
              </span>
            </div>
            <span className="fs-5 fw-bold d-block mw-700px">
              Suas informações são muito importantes para que possamos fornecer
              a melhor experiência durante a jornada. E não se preocupe,
              guardaremos tudo com muito carinho e cuidado.
            </span>
          </div>
          <span className="btn btn-success flex-shrink-0 ms-md-auto">
            Completar Perfil
          </span>
        </div>
        {showModal == 'profile' && (
          <UserProfileValidationModal id={user.id} show onHide={closeModal} />
        )}
      </>
    )

  if (
    user &&
    user.roles &&
    !router.asPath.includes('/minha-conta/conexoes') &&
    user.roles.length <= 0
  )
    return (
      <>
        <Link
          href="/minha-conta/conexoes?redirect=true"
          passHref
          className="bg-dark w-100 rounded-2 p-10 pt-9 mb-10 text-white fw-bolder d-flex align-items-center flex-wrap flex-md-nowrap cursor-pointer"
        >
          <div className="pe-md-10 mb-4 mb-md-0">
            <div className="d-block mb-2 fs-1">
              <span className="fw-bolder">{userFirstName}</span>
              <span
                className="fw-bold"
                // style={{
                //   background: 'linear-gradient(to right, #12CE5D 0%, #FFD80C 100%)',
                //   '-webkit-background-clip': 'text',
                //   '-webkit-text-fill-color': 'transparent'
                // }}
              >
                , crie sua primeira conexão
              </span>
            </div>
            <span className="fs-5 fw-bold d-block mw-700px">
              Se conecte a uma IES, Federação, Núcleo ou EJ para começar a usar
              o Portal BJ
            </span>
          </div>
          <span className="btn btn-success flex-shrink-0 ms-md-auto">
            Criar conexão
          </span>
        </Link>
        {showModal == 'profile' && (
          <UserProfileValidationModal id={user.id} show onHide={closeModal} />
        )}
      </>
    )
  return <></>
}
