import classNames from 'classnames'
import Link from 'next/link'
import { isMobile } from 'react-device-detect'

export const Button = ({
  href,
  className = '',
  icon,
  iconClassName = '',
  children,
  ...rest
}) => {
  const classes = classNames(className, 'd-flex align-items-center gap-2', {
    'btn-icon': isMobile && icon
  })

  const content = icon ? (
    <>
      <i className={`fa-regular fa-${icon} ${iconClassName}`}></i>
      <span className="d-none d-md-block">{children}</span>
    </>
  ) : (
    children
  )

  if (href)
    return (
      <Link href={href} className={classes} {...rest}>
        {content}
      </Link>
    )

  return (
    <button className={classes} {...rest}>
      {content}
    </button>
  )
}
