import { NextSeo } from 'next-seo'
import { Helmet } from 'react-helmet'

import { UsersProfileValidationWidget } from '@users/ProfileValidationWidget'

import LockPage from './LockPage'
import PageHeader from './PageHeader'

export const Layout = ({
  icon,
  title,
  description,
  pageTitle,
  breadcrumbs,
  navLinks,
  pageHeader,
  lock = { condition: false },
  children,
  skipHeader = false
}) => {
  const withPageHeader = !!title && !skipHeader

  return (
    <LockPage {...lock}>
      <NextSeo title={title} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <UsersProfileValidationWidget />
      {withPageHeader && (
        <PageHeader
          icon={icon}
          title={title}
          breadcrumbs={breadcrumbs}
          navLinks={navLinks}
          skipHeader={skipHeader}
        />
      )}
      {pageHeader}
      {children}
    </LockPage>
  )
}

export default Layout
export { PageHeader }
