import _ from 'lodash'
import Link from 'next/link'
import { Pagination, Autoplay, EffectFade } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import ConditionalWrapper from '@/components/ConditionalWrapper'

import styles from './styles.module.scss'

const HomeFeaturedSmallCard = ({
  link_url,
  open_link_in_new_tab,
  background_image
}) => {
  return (
    <ConditionalWrapper
      condition={!!link_url}
      wrapper={children => (
        <Link
          href={link_url}
          passHref
          target={open_link_in_new_tab ? '_blank' : '_self'}
        >
          {children}
        </Link>
      )}
    >
      <div className="ratio ratio-16x9">
        <img
          src={_.get(background_image, 'file.url')}
          alt=""
          className="d-block obj-fit-cover obj-position-center"
        />
      </div>
    </ConditionalWrapper>
  )
}

export const HomeFeaturedCards = ({ featuredCards }) => {
  const primaryFeaturedCards = featuredCards.filter(
    card => !card.kind || card.kind == 'primary'
  )
  const secondaryFeaturedCard = featuredCards.find(
    card => card.kind == 'secondary'
  )
  const tertiaryFeaturedCard = featuredCards.find(
    card => card.kind == 'tertiary'
  )

  return (
    <div className="row g-5">
      <div className="col-xl-8">
        <Swiper
          modules={[Pagination, Autoplay, EffectFade]}
          effect="fade"
          autoplay={{ delay: 5000 }}
          pagination={{ clickable: true }}
          className="mb-xl-5"
        >
          {primaryFeaturedCards &&
            primaryFeaturedCards.map(featured => (
              <SwiperSlide key={`featured-${featured.id}`}>
                <ConditionalWrapper
                  condition={!!featured.link_url}
                  wrapper={children => (
                    <Link
                      href={featured.link_url}
                      passHref
                      target={
                        featured.open_link_in_new_tab ? '_blank' : '_self'
                      }
                    >
                      {children}
                    </Link>
                  )}
                >
                  <div className="rounded-3 d-flex flex-column bg-primary w-100 overlay overlay-show overflow-hidden">
                    <div className="ratio ratio-16x9">
                      <img
                        src={_.get(featured, 'background_image.file.url')}
                        alt=""
                        className="d-block obj-fit-cover obj-position-center"
                      />
                    </div>

                    {featured.title && (
                      <div
                        className={`overlay-layer rounded-3 ${styles['home-featureds-slider-overlay-layer']}`}
                      >
                        <div className="text-white mt-auto p-8 z-index-1 position-relative w-100">
                          <span className="fs-2x text-uppercase d-block m-0 fw-bolder text-white">
                            {featured.title}
                          </span>
                          <span
                            className="fs-5 d-block mb-3 text-gray-300 fw-bold lh-sm"
                            style={{ maxWidth: '370px' }}
                          >
                            {featured.description}
                          </span>
                          {featured.link_url && featured.link_title && (
                            <span className="text-white text-hover-primary fw-bold text-lowercase">
                              {featured.link_title}
                              <i className="fas fa-arrow-right text-white ms-2"></i>
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </ConditionalWrapper>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div className="col-6 col-xl-4">
        {secondaryFeaturedCard && (
          <div className="d-none d-xl-flex w-100 bg-primary overflow-hidden card card-stretch-50 rounded-3 mb-5">
            <HomeFeaturedSmallCard {...secondaryFeaturedCard} />
          </div>
        )}
        {tertiaryFeaturedCard && (
          <div className="d-none d-xl-flex w-100 bg-primary overflow-hidden card card-stretch-50 rounded-3">
            <HomeFeaturedSmallCard {...tertiaryFeaturedCard} />
          </div>
        )}
        {secondaryFeaturedCard && (
          <div className="d-xl-none w-100 bg-primary overflow-hidden card rounded-3 mb-5">
            <HomeFeaturedSmallCard {...secondaryFeaturedCard} />
          </div>
        )}
      </div>
      <div className="col-6">
        {tertiaryFeaturedCard && (
          <div className="d-xl-none d-xl-flex w-100 bg-primary overflow-hidden card rounded-3">
            <HomeFeaturedSmallCard {...tertiaryFeaturedCard} />
          </div>
        )}
      </div>
    </div>
  )
}
