import _ from 'lodash'

export const basePath = '/dashboard/links-importantes'
export const apiBasePath = '/portal/home/cards'
export const icon = 'far fa-link'
export const humanName = {
  singular: 'Link Importante',
  plural: 'Links Importantes'
}
export const header = {
  toolbarLinks: [
    {
      text: 'Adicionar',
      url: '/dashboard/links-importantes/adicionar',
      className: 'btn btn-light-primary btn-sm'
    }
  ]
}
export const getBreadcrumbs = (items = []) => {
  const breadcrumbs = [
    {
      slug: 'Links',
      title: humanName.plural,
      href: basePath
    },
    ...items
  ]

  return breadcrumbs
}
export const kinds = [
  {
    name: 'Brasil Júnior',
    slug: 'bj',
    variant: 'primary',
    icon: 'chart-network'
  },
  {
    name: 'Documentos MEJ',
    slug: 'documents',
    variant: 'dark',
    icon: 'file-zipper'
  },
  { name: 'Novidades', slug: 'news', variant: 'warning', icon: 'sparkles' },
  { name: 'Eventos', slug: 'events', variant: 'success', icon: 'calendar-star' }
]
export const getKind = slug => kinds.find(kind => kind.slug == slug)
export const getConfig = (props = {}) => {
  const {} = props

  const config = {
    basePath,
    apiBasePath,
    icon,
    humanName,
    header,
    getBreadcrumbs,
    kinds,
    getKind
  }

  return config
}

export default getConfig
