import Image from 'next/image'
import Link from 'next/link'
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash'

const HomeDataCard = ({ children }) => (
  <div className="position-relative me-2">
    <div
      className="bg-white shadow  rounded-3 p-6 h-225px position-absolute position-absolute top-0 start-0 w-100 h-100 me-2"
      style={{ transform: 'translateY(-30px) scale(0.9)' }}
    />
    <div
      className="bg-white shadow  rounded-3 p-6 h-225px position-absolute position-absolute top-0 start-0 w-100 h-100 me-2"
      style={{ transform: 'translateY(-15px) scale(0.95)' }}
    />
    <div className="bg-white shadow  rounded-3 p-6 h-225px position-relative me-2 z-index-1">
      {children}
    </div>
  </div>
)

const HomeData = ({
  top5Federations,
  greenLightedEjsTracking,
  highGrowthEjsTracking,
  highGrowthTracking,
  ej,
  federation
}) => {
  const indicatorsNames = {
    revenue: 'Faturamento',
    projects: 'Projetos',
    members_that_executed: 'Membros que executam',
    coop_actions: 'Ações compartilhadas',
    events_participation: 'Participação em eventos',
    impact_projects: 'Projetos de impacto'
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <span className="fs-1 text-gray-700 text-muted text-uppercase mb-10 fw-bolder">
          Dados
        </span>
      </div>
      <div className="row">
        {/* <div className="col-md-4 mb-16">
          <HomeDataCard>
            <div
              className="symbol symbol-50px symbol-circle position-absolute top-0 start-100 translate-middle me-4"
              style={{ width: '50px' }}
            >
              <img
                src="/misc/brazil-badge.png"
                alt=""
                width="48"
                height="48"
                className="symbol-label obj-fit-cover obj-position-center"
              />
            </div>

            <span className="text-uppercase fs-3 mb-4 d-block">
              <span className="fw-bolder text-gray-700">TOP 5</span> Federações
              no <span className="text-success fw-bolder">Verde</span>
            </span>
            {top5Federations &&
              top5Federations.slice(0, 5).map(federation => (
                <div
                  key={federation.id}
                  className="d-flex w-100 align-items-center mb-1"
                >
                  <Link
                    href={`/federacoes/${federation.id}`}
                    passHref
                    target="_blank"
                    className="fs-4 fw-bolder mw-200px overflow-hidden text-nowrap text-gray-600 text-hover-primary"
                  >
                    {federation.name}
                  </Link>
                  <span className="border-top flex-grow-1 mx-2"></span>
                  <span className="fw-bolder text-success fs-4">
                    {federation.green_percent.toFixed(2).replace('.00', '')}%
                  </span>
                </div>
              ))}

            <Link
              href={'/painel-das-instancias'}
              passHref
              className="bg-white shadow position-absolute top-100 start-50 translate-middle rounded-2 d-flex px-2 py-1 fw-bolder fs-7 align-items-center text-gray-600 text-hover-primary text-center text-nowrap"
            >
              ir para o painel instancias{' '}
              <i className="fas fa-arrow-right ms-2 text-gray-600"></i>
            </Link>
          </HomeDataCard>
        </div> */}
        {federation && (
          <div className="col-md-6 mb-16">
            <HomeDataCard>
              <div
                className="symbol symbol-50px symbol-circle position-absolute top-0 start-100 translate-middle me-4"
                style={{ width: '50px' }}
              >
                <img
                  src={
                    federation.logo_url ? federation.logo_url : '/missing.jpg'
                  }
                  alt={federation.name}
                  width="48"
                  height="48"
                  className="symbol-label obj-fit-cover obj-position-center"
                />
              </div>

              <span className="text-uppercase fs-3 mb-4 d-block">
                <span className="fw-bolder text-gray-700">TRACKING</span> de EJs
                no <span className="text-success fw-bolder">Verde</span>
              </span>
              <div className="position-relative">
                <PerfectScrollbar
                  options={{ suppressScrollX: true, wheelPropagation: false }}
                  className="scroll scroll-pull"
                >
                  <div style={{ height: '145px' }}>
                    {greenLightedEjsTracking &&
                      _.reverse(
                        _.sortBy(greenLightedEjsTracking, '_semaphore')
                      ).map(ej => (
                        <div
                          key={ej.id}
                          className="d-flex w-100 align-items-center mb-1"
                        >
                          <Link
                            href={`/ejs/${ej.slug}`}
                            passHref
                            target="_blank"
                            className="fs-5 fw-bolder mw-200px overflow-hidden text-nowrap text-gray-600 text-hover-primary"
                          >
                            {ej.name}
                          </Link>
                          <span className="border-top flex-grow-1 mx-2"></span>
                          <span
                            className={`fw-bolder text-${ej?.high_growth_semaphore_palette?.area} fs-5`}
                          >
                            {(ej._semaphore * 100).toFixed(2)}%
                          </span>
                        </div>
                      ))}
                    <div className="d-block w-100 h-20"></div>
                  </div>
                </PerfectScrollbar>
                <div
                  className="position-absolute bottom-0 start-0 h-50px w-100 user-select-none pointer-events-none"
                  style={{
                    background:
                      'linear-gradient(to top, var(--bs-white), transparent)'
                  }}
                ></div>
              </div>

              <Link
                href={`/federacoes/${federation.id}`}
                passHref
                className="bg-white shadow position-absolute top-100 start-50 translate-middle rounded-2 d-flex px-2 py-1 fw-bolder fs-7 align-items-center text-gray-600 text-hover-primary text-center text-nowrap"
              >
                ver perfil da federação{' '}
                <i className="fas fa-arrow-right ms-2 text-gray-600"></i>
              </Link>
            </HomeDataCard>
          </div>
        )}
        {ej && (
          <div className="col-md-6 mb-16">
            <HomeDataCard>
              <div
                className="symbol symbol-50px symbol-circle position-absolute top-0 start-100 translate-middle me-4"
                style={{ width: '50px' }}
              >
                <img
                  src={ej.logo_url ? ej.logo_url : '/missing.jpg'}
                  alt={ej.name}
                  width="48"
                  height="48"
                  className="symbol-label obj-fit-cover obj-position-center"
                />
              </div>

              <span className="text-uppercase fs-3 mb-4 d-block">
                <span className="fw-bolder text-gray-700">TRACKING</span> da EJ
              </span>

              {highGrowthTracking &&
                Object.keys(highGrowthTracking[0]).map(key => (
                  <div
                    key={`tracking-${key}`}
                    className="d-flex w-100 align-items-center mb-1 text-nowrap"
                  >
                    <span className="fs-5 fw-bolder">
                      {indicatorsNames[key]}
                    </span>
                    <span className="border-top flex-grow-1 mx-2"></span>
                    <span
                      className={`fw-bolder text-${_.get(
                        highGrowthTracking,
                        `0.${key}.semaphore_palette.area`
                      )} fs-5`}
                    >
                      {(
                        _.get(highGrowthTracking, `0.${key}.semaphore`, 0) * 100
                      ).toFixed(0)}
                      %{' '}
                      {/* <small className="opacity-75">(12,3k/11,9k)</small> */}
                    </span>
                  </div>
                ))}

              <Link
                href={`/ejs/${ej.slug}`}
                passHref
                className="bg-white shadow position-absolute top-100 start-50 translate-middle rounded-2 d-flex px-2 py-1 fw-bolder fs-7 align-items-center text-gray-600 text-hover-primary text-center text-nowrap"
              >
                ver perfil da ej{' '}
                <i className="fas fa-arrow-right ms-2 text-gray-600"></i>
              </Link>
            </HomeDataCard>
          </div>
        )}
      </div>
    </>
  )
}

export default HomeData
