import { useEffect, useState } from 'react'

import ls from 'local-storage'
import moment from 'moment'

import { useSessionContext } from '@tootz/react-admin'

import { api } from '@/services/api'
import { Layout } from '@/components/Layout'

import HomeCards from './Cards'
import HomeData from './Data'
import { HomeFeaturedCards } from './FeaturedsSlider'

const Home = () => {
  const { user } = useSessionContext()
  const [data, _setData] = useState(null)
  const featuredCards = _.get(data, 'featureds', [])
  const disableDashboard =
    moment().isAfter(moment('2023-09-09T00:00:00')) &&
    moment().isBefore(moment('2023-09-17T00:00:00'))

  const setData = data => {
    _setData({
      motivationalPhrases: data.motivationals,
      featureds: data.featureds,
      latestPosts: _.reverse(data.latest_posts),
      cards: data.cards,
      connectMejPosts: data.connect_mej,
      top5Federations: data.top_federations,
      greenLightedEjsTracking: data.tracking_green_lighted_ejs,
      highGrowthEjsTracking: data.tracking_tracking_ac_ejs,
      ej: data.ej,
      federation: data.federation,
      highGrowthTracking: data.tracking_ac
    })
  }

  useEffect(() => {
    const lsData = ls.get('__bj-home-data')

    if (lsData) setData(lsData)

    const load = async () => {
      api
        .get('/portal/pages/home')
        .then(response => {
          ls.set('__bj-home-data', {
            ...response.data.record,
            timestamp: moment()
          })
          setData(response.data.record)
        })
        .catch(error => console.log('error', error))
    }

    load()
  }, [])

  return (
    <Layout>
      <div className="d-flex flex-wrap justify-content-center justify-content-md-between  border-bottom border-gray-300 pb-5 mb-2 align-items-center mb-6">
        <h1 className="text-gray-700 fw-bolder mb-0 fs-1x">
          Olá, {_.get(user?.name.split(' '), 0, '')}!
        </h1>
        {data?.motivationalPhrases && data?.motivationalPhrases.length > 0 && (
          <div className="d-flex flex-column mb-6 text-lg-end">
            <span className="text-gray-600 fs-6 mw-400px">
              {`"${data.motivationalPhrases[0].title}"`}
            </span>
            <span className="fw-bolder text-gray-600 fs-6">
              {data.motivationalPhrases[0].author}
            </span>
          </div>
        )}
      </div>

      <HomeFeaturedCards featuredCards={featuredCards} />

      <HomeCards />

      {/* <div className="row align-items-end">
        <div className="col-md-5 col-lg-4">
          <div className="ratio ratio-16x9 mb-10 overflow-hidden rounded">
            <ReactPlayer
              width="100"
              height="100"
              config={{
                youtube: {
                  playerVars: { showinfo: 0 }
                }
              }}
              url="https://www.youtube.com/watch?v=4Hpb3X9uf7I"
            />
          </div>
        </div>
        <div className="col-md-7 col-lg-8">
          <HomeCards cards={data?.cards} />
        </div>
      </div> */}

      {!disableDashboard && (
        <HomeData
          top5Federations={data?.top5Federations}
          greenLightedEjsTracking={data?.greenLightedEjsTracking}
          highGrowthEjsTracking={data?.highGrowthEjsTracking}
          highGrowthTracking={data?.highGrowthTracking}
          ej={data?.ej}
          federation={data?.federation}
        />
      )}
    </Layout>
  )
}

export default Home
