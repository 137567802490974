import { Modal } from '@tootz/react-admin/components'

const LockPage = ({
  title = 'Atenção!',
  text,
  content,
  condition,
  children
}) => {
  return (
    <>
      {children}
      {condition && (
        <Modal show={true} centered>
          <Modal.Header>
            <Modal.Icon className="fal fa-lock-keyhole text-danger" />
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="fs-4 text-gray-500 fw-bold text-center d-block">
              {text}
            </span>
            {content}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default LockPage
