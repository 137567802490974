import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import Link from 'next/link'

import { Dropdown } from '@tootz/react-admin/components'

import { Button } from '@/components/Button'

import styles from './styles.module.scss'

const PageHeader = ({
  title,
  description,
  icon,
  breadcrumbs,
  navLinks = [],
  skipHeader,
  children
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {!skipHeader && (
        <div
          id="ttz_app_toolbar"
          className={`app-toolbar pb-3 pb-lg-6 ${styles['page-header']}`}
        >
          <div className={styles['page-header-main']}>
            {!isMobile &&
              icon &&
              (!breadcrumbs || breadcrumbs.length === 0) && (
                <i
                  className={`${styles['page-header-icon']} fal fa-${icon}`}
                ></i>
              )}

            <h1
              className={icon || breadcrumbs ? styles['page-title'] : ''}
              dangerouslySetInnerHTML={{ __html: title }}
            />

            {breadcrumbs && breadcrumbs.length > 0 && (
              <div className={styles['page-header-breadcrumb-wrapper']}>
                <ul className={styles['page-header-breadcrumb']}>
                  {!isMobile && (
                    <>
                      <li>
                        <Link href="/" passHref>
                          <i className="fal fa-browser"></i>
                        </Link>
                      </li>
                      {breadcrumbs.map(
                        ({ active, title, url, href, target }, index) => (
                          <li
                            key={`page-header-breadcrumb-${index}`}
                            className={`${active ? 'active' : ''}`}
                          >
                            {active ? (
                              <span
                                dangerouslySetInnerHTML={{ __html: title }}
                              />
                            ) : (
                              <Link
                                href={url || href}
                                passHref
                                target={target ? target : '_self'}
                                dangerouslySetInnerHTML={{ __html: title }}
                              ></Link>
                            )}
                          </li>
                        )
                      )}
                    </>
                  )}
                  {isMobile && (
                    <div className="d-flex align-items-center">
                      <li>
                        <Link href="/">
                          <i className="fal fa-browser"></i>
                        </Link>
                      </li>
                      <li>
                        <span>
                          <Dropdown>
                            <Dropdown.Toggle
                              className={
                                'btn-icon btn-body btn-active-light-primary btn-color-primary w-25px h-25px'
                              }
                            >
                              <i className="fa-regular fa-ellipsis fs-7"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {breadcrumbs.map(
                                ({ active, title, href }, index) => (
                                  <Dropdown.Link
                                    href={href}
                                    key={`breadcrumb-dropdown-link-${index}`}
                                    className={`${active ? 'active' : ''}`}
                                  >
                                    {title}
                                  </Dropdown.Link>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </li>
                      {breadcrumbs[breadcrumbs.length - 1] && (
                        <li>
                          <span>{title}</span>
                        </li>
                      )}
                    </div>
                  )}
                </ul>
              </div>
            )}
          </div>

          <div className={styles['page-header-toolbar']}>{children}</div>

          <nav className={styles['page-header-nav']}>
            {breadcrumbs && breadcrumbs.length > 0 && (
              <button
                onClick={() => window.history.back()}
                className={`btn btn-sm btn-icon btn-light-primary px-2 ${
                  navLinks && navLinks.length ? 'me-3' : ''
                }`}
              >
                <i className="fal fa-chevron-left"></i>
              </button>
            )}

            {navLinks
              .filter(
                navLink =>
                  !navLink.hasOwnProperty('condition') || navLink.condition
              )
              .map(
                ({
                  url,
                  href,
                  target,
                  classes,
                  icon,
                  title,
                  condition,
                  ...rest
                }) => (
                  <Button
                    key={url || href}
                    href={url || href}
                    icon={icon}
                    target={target ? target : '_self'}
                    className={
                      classes ? classes : 'btn btn-sm btn-light-primary'
                    }
                    {...rest}
                  >
                    {title}
                  </Button>
                )
              )}
          </nav>
        </div>
      )}
    </>
  )
}

export default PageHeader
